import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
// import Image from "../components/image"
import SEO from "../components/seo"
import PostLink from "../components/postLink"

const IndexPage = () => (
  <Layout>
    <SEO
      title="Home"
      description="Natural Afro Hair Care Home Page. Everything you need to know about natural afro hair care"
    />
    <h1
      style={{
        fontSize: "1.6rem",
        color: "Orchid"
      }}
    >
      Hi Naturals
    </h1>
    <p>
      Welcome to NAHC, Here's our{" "}
      <Link to="/about/" style={{ textDecoration: "none" }}>
        about page
      </Link>
    </p>
    {/* <p>
      <Link to="/test/test/" style={{ textDecoration: "none" }}>
        test sub-directory
      </Link>
    </p> */}
    <p>Go ahead and browse the articles below</p>
    {/* <div style={{ maxWidth: `300px`, marginBottom: `1.45rem` }}>
      <Image />
    </div> */}

    <h2 style={{color:"steelBlue", paddingLeft:"10px", paddingTop:"20px"}} id="articles">ARTICLES</h2>
    
    <PostLink
      title="14 tips on how to grow afro hair"
      description="Finally, everything you need to know about how to grow an afro. We interviewed experts to help find these 14 tips to help you grow your hair fast"
      link="/14-tips-on-how-to-grow-afro-hair/"
    />

    <PostLink
      title="7 vitamins and minerals for growing healthy afro hair"
      description="We need vitamins to grow strong healthy hair but which ones are they? Here's the complete list and exactly where you can find them"
      link="/7-vitamins-for-healthy-hair/"
    />

    <PostLink
      title="The Best Afro Hair Products"
      description="The best natural afro hair products available, a complete list of products you need for every step in your hair care routine"
      link="/afro-hair-products/"
    />

    <PostLink
      title="Wigs For Black Women"
      description="The surprising reasons a wig might be exactly what you need. How a wig can help you on the way to beautiful natural hair"
      link="/wigs-for-black-women/"
    />

    <PostLink
      title="Tips on Box Braids For Black Hair"
      description="What you need to think before you get your hair box braided. Avoiding hair loss and pain. Where to buy, different styles and the difference between synthetic and human braiding hair"
      link="/tips-on-box-braids-for-black-hair/"
    />

    <PostLink
      title="How To Get An Afro"
      description="Growing an afro seems easy enough but if you miss a few important steps, you could slow your own progress or end up with problems that could mean starting again scratch all over again."
      link="/how-to-get-an-afro/"
    />

    <PostLink
      title="5 Reasons Why You Should Deep Condition"
      description="One of the things that still surprises me is the amount of people that are struggling with natural hair care that have never tried a deep condition. Try it now with these tips."
      link="/5-reasons-why-you-should-deep-condition/"
    />

    <PostLink
      title="Going Homemade With Natural Hair Products"
      description="Take matters into your own hands and make hair products that do everything you need to get beautiful hair without the unnecessary man-made ingredients."
      link="/going-homemade-with-natural-hair-products/"
    />

    <PostLink
      title="Afro Hair Trends 2021"
      description="A look into the popularity of black hair styles, trends and techniques for the past year. Find out how your searches compare to other people around the world."
      link="/afro-hair-trends-2021/"
    />

    <PostLink
      title="Black Owned Hair Products"
      description="List of over 100 black owned hair products. Black owned natural hair products/ beauty brands are something to be celebrated. Check out the list and show support."
      link="/black-owned-hair-products/"
    />

    <PostLink
      title="Silk Pillowcases For Hair Care"
      description="How a silk pillowcase can help get you beautiful hair faster, the difference between silk & satin. A guide on which one is best for your needs"
      link="/silk-pillowcases-for-hair-care/"
    />

    <PostLink
      title="Natural Hair Care Glossary"
      description="Finally, the list you've been looking for. Everything you need to know about natural afro hair care. Use this list to help you grow long textured 4c curls"
      link="/natural-hair-care-glossary/"
    />

    <PostLink
      title="Afro Hair T-Shirts"
      description="Beautiful Natural Afro Hair themed t-shirt designs. Comfortable and made to be something you can be proud of. Show everyone that you are part of the natural hair community"
      link="/afro-hair-tshirts/"
    />

    <PostLink
      title="Is 'Black Owned' Racist?"
      description="Is black owned a terrible racist thing that needs to stop or is there some good in it. Let's look at some arguments for both sides on this tough subject"
      link="/is-black-owned-racist/"
    />

    <PostLink
      title="5 Reasons to Keep Your Hair Moisturised"
      description="When it comes to natural hair care (especially in thicker and curlier hair types), moisture control is up there with what should be one of your highest priorities"
      link="/5-reasons-to-keep-your-hair-moisturised/"
    />

    <PostLink
      title="5 Reasons to Try Oil on Natural Hair"
      description="Oils have a very specific use in hair care and you definitely shouldn’t think of them as just another type of conditioner"
      link="/5-reasons-to-try-oil-natural-hair/"
    />

    <PostLink
      title="7 Benefits of Protective Styling for Natural Hair"
      description="The good thing about protecting your hair is that it suffers less damage and can grow to its full length"
      link="/7-benefits-of-protective-styling/"
    />

    <PostLink
      title="How Often you Should Wash Natural Hair"
      description="You have probably heard people telling you not to wash too frequently but this is meaningless advice without understanding why not to do it and how frequent is too frequent?"
      link="/how-often-you-should-wash-natural-hair/"
    />

    <PostLink
      title="Cut off Split Ends for Faster Natural Hair Growth"
      description="Cutting your hair might seem like a bad way to help your hair grow. You want it to grow so why would you make it shorter? Well, there’s a good reason"
      link="/cut-off-split-ends/"
    />

    <PostLink
      title="Apply Product Before Heat For Healthy Hair"
      description="The best way to avoid damage from heat is not to use heat at all. With that being said, heat is essential for achieving certain styles. Despite our name, we are not against heat or making changes to your hair"
      link="/apply-product-before-heat/"
    />

    <PostLink
      title="Wrap Up Your Hair Day and Night"
      description="Wrapping up your hair serves two main purposes. One is to help retain moisture and the other is to help keep your hairstyle neat"
      link="/wrap-up-your-natural-hair/"
    />

    <PostLink
      title="Use Different Products For Your Hair and Scalp"
      description="For those of us that are not so lucky, having a separate care routine for your scalp and hair can make a big difference"
      link="/use-different-products-for-hair-and-scalp/"
    />

    <PostLink
      title="Look After Your Edges"
      description="One of the most common complaints I see is about edges. They are definitely under-appreciated by many people because they think they will be there forever"
      link="/look-after-your-edges/"
    />

    <PostLink
      title="Careful Combing Tips For Natural Hair"
      description="A good combing routine should be pain free and not something you dread. Here are some tips to get you through and make it easier"
      link="/careful-combing-tips-natural-hair/"
    />

    <PostLink
      title="Take Action and Do What Feels Right for You"
      description="When you browse the internet you will see all sorts of advice. That would be advice from blogs including this one, YouTube videos, ebooks etc. There is so much information that it’s hard to know where to start"
      link="/take-action-on-what-feels-right/"
    />

    <PostLink
      title="Natural Hair Education"
      description="Natural hair care should start with an understanding. There are endless tricks and tips for looking after your natural hair and everywhere you turn, this guide will point you in the right direction"
      link="/natural-hair-education/"
    />

    <PostLink
      title="Why Does My Natural Hair Feel Dry Like Straw?"
      description="The key to having beautiful healthy hair that isn’t dry is to have a routine that you have built out through experimentation that matches your hair type"
      link="/why-does-my-natural-hair-feel-dry-like-straw/"
    />
    

    <h2 style={{color:"steelBlue", paddingLeft:"10px", paddingTop:"20px"}} id="brand-reviews">BRAND REVIEWS</h2>

    <PostLink
      title="Cantu Beauty Review"
      description="Everything you need to know about Cantu Beauty and their hair products. Find out if they are right for you and what you should look out for"
      link="/cantu-review/"
    />

    <PostLink
      title="Shea Moisture Review"
      description="Everything you need to know about Shea Moisture and their hair products. Is the Shea Moisture product range right for you?"
      link="/shea-moisture-review/"
    />
    
    <h2 style={{color:"steelBlue", paddingLeft:"10px", paddingTop:"20px"}} id="interviews">INTERVIEWS</h2>

    <PostLink
      title="Entrepreneurship, Modeling and Natural Hair with Celai West"
      description="We talk to the talented and incredibly hard working entrepreneur Celai West. Celai tells us about her unique homemade hair tea!"
      link="/talking-natural-hair-with-celai-west/"
    />

    <PostLink
      title="First Year Doing it Herself - Elise Talks Natural Hair"
      description="We had a chat with the amazing Elise Campbell. She has been looking after her own 4c hair for a year now after taking over from her mother"
      link="/talking-natural-hair-with-elise/"
    />

    <PostLink
      title="Travel The World and Natural Hair with Ebonie Roberge"
      description="We talk natural hair with the beautiful Ebonie Roberge. We learned that the journey to a perfect natural hair routine can be a long one"
      link="/talking-natural-hair-with-ebonie-roberge/"
    />

    <PostLink
      title="Model, Artist and Musician - Natural Hair with Manna McLeod"
      description="We talk natural hair with the beautiful model Manna. We picked up some tips and learn about some new products. Can’t wait for you to read!"
      link="/natural-hair-with-manna/"
    />

    <PostLink
      title="Learning to Love Natural Hair with Sharna"
      description="Sharna tells us about her journey and learning to love & appreciate her natural hair. She talks about growing up mixed race and learning about herself."
      link="/talking-with-sharna/"
    />

    <PostLink
      title="Going Natural with Blessed"
      description="Zambian born Blessed talks about her decision to go natural. We discuss her top hairstyles and which products are best for natural hair."
      link="/talking-with-blessed-shaba/"
    />

    <PostLink
      title="Natural Hair with Model Leah Alexxanderr-Caine"
      description="This week we had a chat with the amazing Leah Alexxanderr-Caine. She is a model born & raised in east London, currently living in Essex."
      link="/talking-with-leah-alexxanderr-caine/"
    />

    <PostLink
      title="Social Media Natural Hair Influencer Tyler Talks Natural Hair Care"
      description="Tyler the natural hair enthusiast from Grenada talks about her routine and her favourite products. She has some amazing hairstyles for black women."
      link="/talking-with-tyler/"
    />

    <h2 style={{color:"steelBlue", paddingLeft:"10px", paddingTop:"20px"}} id="product-reviews">PRODUCT REVIEWS</h2>

    <PostLink
      title="Shea Moisture Jamaican Castor Oil Conditioner for natural hair"
      description="What if there was something to moisturize, strengthen AND help grow your hair? Shea Moisture Leave-in conditioner is truely an incredibible conditioner"
      link="/shea-moisture-jamaican-castor-oil-conditioner-review/"
    />

    {/* <PostLink
      title="Palmer's Coconut Oil Formula Review"
      description="Palmer's Coconut Oil Formula Review"
      link="/palmers-coconut-oil-formula/"
    /> */}
  

    {/* <Link to="/page-2/">Go to page 2</Link> */}
  </Layout>
)

export default IndexPage
